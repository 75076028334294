
export const onInitialClientRender = () => {
  const pathname = window.location.pathname;
  if(pathname.includes("/amp/")){
    // document.querySelector('link[rel="amphtml"]').remove();
  }
  if (process.env.GATSBY_LANG === "en") {
    // let script = document.createElement("script");
    // script.type = "text/plain";
    // script["cookie-consent"] = "tracking";
    // script["defer"] = true;

    // script.innerHTML = `window.dataLayer = window.dataLayer || [];
    //   function gtag(){dataLayer.push(arguments);}
    //   gtag('js', new Date());
      
    //   gtag('config', 'UA-219966612-1');`;
    // document.head.prepend(script);
  }

  if (process.env.GATSBY_LANG === "sv") {
    var script = document.createElement("script");
    script.type = "text/plain";
    script["cookie-consent"] = "tracking";
    script["defer"] = true;

    script.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      
      gtag('config', 'G-PP1G19T52Y');`;
    document.head.prepend(script);
  }

  if (process.env.GATSBY_LANG === "en-IN" && process.env.GATSBY_ENV === "production") {
    
      let gaScript = document.createElement("script");
      gaScript.type = "text/javascript";
      gaScript.innerHTML = `if(typeof ga === "function"){ ga('create', 'G-NFV34T1P45', 'auto'); }`;

      let gtmScript = document.createElement("script");
      gtmScript.type = "text/javascript";
      gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5L5ZZ3D');`;

      document.body.appendChild(gaScript);
      document.head.prepend(gtmScript);

      
  }

  if (process.env.GATSBY_LANG === "ar-AE" && process.env.GATSBY_ENV === "production") {
  
      let gtmScript = document.createElement("script");
      gtmScript.type = "text/javascript";
      gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-W4GXDF2N');`
      document.head.prepend(gtmScript);
  }

  // let deskproScript = document.createElement("script");
  // deskproScript.innerHTML = `
  // window.DESKPRO_MESSENGER_OPTIONS = { language: { id: "1", locale: "en-US" }, helpdeskURL: "https://support.opsio.se", }
  // `;
  // let deskproSourceScript = document.createElement("script");
  // deskproSourceScript.id = "dp-messenger-loader";
  // deskproSourceScript.src =
  //   "https://support.opsio.se/dyn-assets/pub/build/messenger/loader.js?v=1652784614";

  // deskproScript.type = "text/javascript";
  // deskproScript["cookie-consent"] = "functionality";

  // deskproSourceScript.type = "text/javascript";
  // deskproSourceScript["cookie-consent"] = "functionality";

  // if(process.env.GATSBY_LANG !== "en-IN") {
  //   document.head.prepend(deskproSourceScript);
  //   document.head.prepend(deskproScript);
  // }

  // let zohoScript = document.createElement("script");
  // zohoScript.src = "https://crm.zoho.eu/crm/javascript/zcga.js";
  // zohoScript.type = "text/plain";
  // zohoScript["cookie-consent"] = "functionality";
  // document.body.prepend(zohoScript);

  // if (process.env.GATSBY_LANG === "sv") {
  //   let helloBarScript = document.createElement("script");
  //   helloBarScript.src =
  //     "https://my.hellobar.com/d0fe6a557b7bff688bf8c8debda41d46810adf0b.js";

  //   helloBarScript.type = "text/javascript";
  //   helloBarScript["cookie-consent"] = "functionality";

  //   document.body.prepend(helloBarScript);
  // }

  // if (process.env.GATSBY_LANG === "en") {
  //   let inlinksScript = document.createElement("script");
  //   inlinksScript.src = "https://jscloud.net/lzs/20227/inlinks.js";
  //   inlinksScript.defer = true;
  //   inlinksScript.type = "text/javascript";

  //   document.body.prepend(inlinksScript);
  // } else if (process.env.GATSBY_LANG === "en-IN") {
  //   let inlinksScript = document.createElement("script");
  //   inlinksScript.src = "https://jscloud.net/lzs/20228/inlinks.js";
  //   inlinksScript.defer = true;
  //   inlinksScript.type = "text/javascript";
  //   document.body.prepend(inlinksScript);
  // }

  let source;
  if (process.env.GATSBY_LANG === "en" && process.env.GATSBY_ENV === "production") {
    source = "https://www.googletagmanager.com/ns.html?id=GTM-5R22WDV";
  } else if (process.env.GATSBY_LANG === "sv" && process.env.GATSBY_ENV === "production"){
    source = "https://www.googletagmanager.com/ns.html?id=GTM-5L5ZZ3D";
  } else if (process.env.GATSBY_LANG === "en-IN" && process.env.GATSBY_ENV === "production"){
    source = "https://www.googletagmanager.com/ns.html?id=GTM-MRDF87C";
  } else if (process.env.GATSBY_LANG === "ar-AE" && process.env.GATSBY_ENV === "production"){
    source = "https://www.googletagmanager.com/ns.html?id=GTM-W4GXDF2N";
  }

  let gtmNoScript = document.createElement("noscript");
  let gtmIframe = document.createElement("iframe");
  gtmIframe.src = source;
  gtmIframe.height = 0;
  gtmIframe.width = 0;
  gtmIframe.style = "display:none;visibility:hidden";
  gtmNoScript.appendChild(gtmIframe);
  document.body.prepend(gtmNoScript);
};