exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-etnetwork-case-study-js": () => import("./../../../src/pages/etnetwork-case-study.js" /* webpackChunkName: "component---src-pages-etnetwork-case-study-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-aws-public-support-statement-js": () => import("./../../../src/pages/services/aws-public-support-statement.js" /* webpackChunkName: "component---src-pages-services-aws-public-support-statement-js" */),
  "component---src-pages-sewn-technology-case-study-js": () => import("./../../../src/pages/sewn-technology-case-study.js" /* webpackChunkName: "component---src-pages-sewn-technology-case-study-js" */),
  "component---src-pages-windows-migrations-on-aws-js": () => import("./../../../src/pages/windows-migrations-on-aws.js" /* webpackChunkName: "component---src-pages-windows-migrations-on-aws-js" */),
  "component---src-templates-aws-page-js": () => import("./../../../src/templates/awsPage.js" /* webpackChunkName: "component---src-templates-aws-page-js" */),
  "component---src-templates-blogdetail-js": () => import("./../../../src/templates/blogdetail.js" /* webpackChunkName: "component---src-templates-blogdetail-js" */),
  "component---src-templates-campaign-js": () => import("./../../../src/templates/campaign.js" /* webpackChunkName: "component---src-templates-campaign-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-industry-page-js": () => import("./../../../src/templates/industryPage.js" /* webpackChunkName: "component---src-templates-industry-page-js" */),
  "component---src-templates-new-services-js": () => import("./../../../src/templates/newServices.js" /* webpackChunkName: "component---src-templates-new-services-js" */),
  "component---src-templates-new-technologies-js": () => import("./../../../src/templates/newTechnologies.js" /* webpackChunkName: "component---src-templates-new-technologies-js" */),
  "component---src-templates-platform-js": () => import("./../../../src/templates/platform.js" /* webpackChunkName: "component---src-templates-platform-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-successstories-js": () => import("./../../../src/templates/successstories.js" /* webpackChunkName: "component---src-templates-successstories-js" */),
  "component---src-templates-successstorydetail-js": () => import("./../../../src/templates/successstorydetail.js" /* webpackChunkName: "component---src-templates-successstorydetail-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-why-opsio-js": () => import("./../../../src/templates/why-opsio.js" /* webpackChunkName: "component---src-templates-why-opsio-js" */)
}

